<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  label="LBLREMOVE"
                  icon="remove" />
                <c-btn
                  v-if="editable"
                  label="LBLSAVE"
                  icon="save" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
                <div class="col-6">
                  <c-text
                    :editable="editable"
                    label="검사항목명"
                    name="col1"
                    v-model="data.col1">
                  </c-text>
                </div>
                <div class="col-3">
                  <c-text
                    :editable="editable"
                    label="법적허용 기준값"
                    name="col2"
                    v-model="data.col2">
                  </c-text>
                </div>
                <div class="col-3">
                  <c-text
                    :editable="editable"
                    label="자체관리 기준값"
                    name="col3"
                    v-model="data.col3">
                  </c-text>
                </div>
                <div class="col-4">
                  <c-plant :required="true" type="edit" name="col0" v-model="data.col0" />
                </div>
                <div class="col-4">
                  <c-radio
                    :editable="editable"
                    :comboItems="useFlagItems"
                    label="LBLUSEFLAG"
                    name="useFlag"
                    v-model="data.useFlag">
                  </c-radio>
                </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>
<script>
// import selectConfig from '@/js/selectConfig';
// import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'water-master03-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
          psiDocumentId: '',
          documentClassCd: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      data: {
        col0: '',
        col1: 'BOD',
        col2: '120',
        col3: '90',
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getData();
    },
    getData() {
    },
  }
};
</script>